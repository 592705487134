import React, { CSSProperties, ReactElement, ReactNode, useState } from 'react';
import styles from './CustomDropdown.styles.module.scss';
import { CloseIcon } from '@/shared/icons/index';

const CustomDropdown = ({
    onChange,
    onClear,
    items,
    name,
    label,
    style,
    className,
    icon,
    value
}: {
    value?: string | number
    onChange: any;
    onClear?: any;
    items: { label: string | number; value: string | number; disabled?: boolean }[];
    name: string;
    label: string;
    style?: CSSProperties;
    className?: string;
    icon?: ReactElement;
}): ReactElement => {
    const defaultStyles: CSSProperties = { ...style };

    const [isDirty, setIsDirty] = useState<boolean>(false);
    return (
        <div style={defaultStyles} className={`${className} ${styles["select-container"]}`}>
            <select
                onChange={(e) => {
                    onChange(e);
                    setIsDirty(true);
                }}
                name={name}
                className={`${styles['container']} ${icon ? styles['container-icon'] : 'container-background'}`}
            >
                {!isDirty && (
                    <option value={''} selected>
                        {label}
                    </option>
                )}
                {items && items.map((item) => <option value={item.value} selected={value === item.value} disabled={item.disabled}>{item.label}</option>)}
            </select>
            {icon ? icon : <></>}
        </div>
    );
};

export default CustomDropdown;
